import React, { useState } from 'react'
import * as yup from "yup";
import { yupValidator } from 'lib/yup-validator'
import { Controller, useForm } from 'react-hook-form'

import List from 'atoms/List'
import Text from 'atoms/Text'
import Input from 'atoms/Input'
import Button from 'atoms/Button';
import Alert from 'molecules/Alert'
import Textarea from 'atoms/forms/Textarea';
import ReactSelect from 'atoms/forms/ReactSelect';
import IconWithLabel from 'molecules/IconWithLabel';
import DoctorImageCaptionCard from 'molecules/v1/DoctorImageCaptionCard';
import LinkAnchor from 'atoms/LinkAnchor';
import Skeleton from 'react-loading-skeleton';
import Checkbox from 'molecules/Forms/Checkbox';
import Form from '../common/Form';
import { USER_INPUT_DATA_TYPES } from 'constant';
import AuthorDetails from 'molecules/AuthorDetails';
import moment from 'moment';

const BookAnAppointment = ({ className = '', heading, alertMsg, setAlertMsg, benefits, doctorName, doctorAvatar, errors, handleSubmit = () => { }, onSubmit = () => { }, register = () => { }, control, cityList, appointmentBenefits, isGridView = true, doctorId, specializationListData, isLoadingAppointBenefits, setValue, disableFieldKey, isPopup=false, disableIsFollowUp=false, isLoading=false, selectedSlotTime, consultationFee, mobile }) => {
    const [promotional, setPromotional] = useState(true)
    return (
        <div className={`${className} overflow-hidden  px-2 md:px-3 pb-5`}>
            <div className='mb-3 md:flex md:items-center md:justify-between'>
                <AuthorDetails authorName={doctorName} avatar={doctorAvatar} className='flex justify-between w-full'>
                    <Text type='caption' fontWeight={600} className='text-gray-900 text-xl flex mb-1.5'>
                        <span className='whitespace-nowrap mr-1'>{doctorName}</span>
                    </Text>
                    <Text type='caption' className='text-gray-700 text-16-18'>
                        <div className='flex justify-between w-full'>
                            <span className='whitespace-nowrap mr-1'>{moment(new Date(selectedSlotTime)).format("hh:mm A, DD MMM, YYYY")}</span>
                            <span className='whitespace-nowrap mr-1'>{consultationFee ? `₹${consultationFee} fee` : 'Free'}</span>
                        </div>
                    </Text>
                </AuthorDetails>
            </div>
            <div className='border-2 border-primary1-200 rounded-2xl overflow-hidden'>
              <Text className="bg-primary1-100 px-4 py-4 text-xl md:text-lg.5 font-semibold text-primary1-900">Fill your Details for Consultation</Text>
                {appointmentBenefits && 
                <div className={isPopup ? 'w-full grid lg:grid-cols-4 grid-cols-2  gap-x-1 md:gap-x-4 sm:gap-x-4 gap-y-3 mt-1 mb-5' : 'w-full grid grid-cols-[130px_150px] md:grid-cols-[130px_180px] justify-between gap-x-3 md:gap-x-20 gap-y-3 mt-1 mb-5'}>
                    {appointmentBenefits.map(item => (<IconWithLabel
                        key={item?.id}
                        containerClass='gap-2 md:gap-2 w-full items-start'
                        iconClass='w-[24px] md:w-[30px]'
                        imageSrc={item?.src}
                        imageWidth={30} imageHeight={30}
                        label={item?.reqLabel}
                        labelClass={`${item?.style} flex-wrap text-sm font-normal font-open text-gray-900 -mt-1.5`}
                    />
                    ))}
                </div>}

                {isLoadingAppointBenefits &&
                    <div className='w-full grid grid-cols-[130px_150px] md:grid-cols-[130px_180px] justify-between gap-x-3 md:gap-x-20 gap-y-3 mt-1 mb-5'>
                        {Array.from({ length: 4 }).map((_, index) => (
                            <div className='flex gap-2' key={index}>
                                <Skeleton width={30} height={25} />
                                <Skeleton width={100} height={25} />
                            </div>
                        ))}
                    </div>
                }
                <Form className={`px-5 pb-5 ${isGridView ? 'grid-cols-1 md:grid-cols-2 gap-x-3.5' : ''} mt-2.5 grid`} onSubmit={handleSubmit(onSubmit)}>

                    <Input 
                        inputDataType={USER_INPUT_DATA_TYPES.alphabets}
                        placeholder="Full Name*" 
                        register={register} 
                        id="fullName"
                        minimumLength={2}
                        maximumLength={60} 
                        mandatory={true}
                        error={errors.fullName} 
                        isRequiredField={true} 
                        disabled={disableIsFollowUp} 
                    />

                    <Input
                        inputDataType={USER_INPUT_DATA_TYPES.mobile}
                        placeholder="Mobile Number*"
                        isMobileNumber={true}
                        value={mobile}
                        id="mobile"
                        register={register}
                        error={errors.mobile}
                        isRequiredField={true}
                        patternRequired={true}
                        reger="^[789]\d{9}$"
                        minimumLength={10}
                        maximumLength={10}
                        mandatory={true}
                        setValue={setValue}
                        disabled={disableFieldKey || disableIsFollowUp}
                    />

                    <Input
                        inputDataType={USER_INPUT_DATA_TYPES.number}
                        placeholder='Pincode*'
                        id="pincode"
                        register={register}
                        error={errors.pincode}
                        mandatory={true}
                        isRequiredField={true}
                        minimumLength={6}
                        maximumLength={6}
                    />


                    <Input
                        type="text"
                        placeholder='State*'
                        id="state"
                        register={register}
                        error={errors.state}
                        isRequiredField={true}
                        disabled={true}
                    /> 

                    {!doctorId ?
                        <div className={`${isGridView ? 'md:col-span-2 col-span-1' : ''}`}>
                            <Controller
                                control={control}
                                name="specializationIdForm"
                                render={({ field: { onChange, name, value } }) => (
                                    <ReactSelect
                                        placeholder="Select doctor's specialization"
                                        name={name}
                                        onChange={(data) => {
                                            onChange(data?.value);
                                        }}
                                        value={specializationListData?.find((c) => c.value === value || null)}
                                        options={specializationListData}
                                        error={errors.specializationIdForm}
                                        customZIndex={100}
                                    />
                                )}
                            />
                        </div>
                        : null
                    }
                    <div className={`${isGridView ? 'md:col-span-2 col-span-1' : ''}`}>
                        <Textarea
                            inputDataType={USER_INPUT_DATA_TYPES.nonEmojiText}
                            textareaRows={2}
                            placeholder="Please provide details about your health concerns/symptoms..."
                            id="queryPrompt"
                            mandatory={true}
                            minimumLength={10}
                            maximumLength={4000}
                            register={register}
                            error={errors.queryPrompt}
                            isLabelLine={true}
                        />
                    </div>
                    <div className={`${isGridView ? 'md:col-span-2 col-span-1' : ''} text-xs text-center`}>
                        <Checkbox className="mb-4 mt-2 text-center text-14-22 text-gray-600 w-full flex justify-center" label={`Get updates on your WhatsApp number`} handlerCheckboxChange={() => { setPromotional(!promotional)}} checked={promotional}/>
                        By continuing, I agree to the <LinkAnchor className='inline-block text-primary1-500' href="/terms-of-service" target="_blank" >Terms & Conditions</LinkAnchor> of Requesting Appointment
                    </div>
                    <div className={`${isGridView ? 'md:col-span-2 col-span-1 mt-2' : ''} items-center flex justify-center mt-2`}>
                        <Button disabled={isLoading || !promotional} type='submit' size='small' className='mx-auto max-w-[300px] md:mx-0 rounded-lg w-full px-0'>Continue</Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default BookAnAppointment